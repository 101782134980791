import axios from "axios"

import { UserSettings } from "@/models/User"

export default class UserSettingsService {
  pageSize: number = 20

  async createUserSettings(userId: string, settings: UserSettings): Promise<UserSettings> {
    const res = await axios.post<UserSettings>(`usersettings?userId=${userId}`, settings)
    return res.data
  }

  async updateUserSettings(userId: string, settings: UserSettings): Promise<UserSettings> {
    const res = await axios.put<UserSettings>(`usersettings/${settings.id}`, settings)
    return res.data
  }
}
